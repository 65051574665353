import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

const iconPropertyTypes = {
  dPath: PropTypes.string.isRequired,
  fill: PropTypes.string,
  focusable: PropTypes.bool,
  height: PropTypes.number,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
};

type IconProperties = InferProps<typeof iconPropertyTypes> & {
  fill?: string;
  focusable?: boolean;
  height?: number;
  tabIndex?: number;
  width?: number;
};

const Icon = ({
  width = 24, height = 24, dPath, title, fill, ...rest
}: IconProperties) => (
  <svg
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...rest}
  >
    <title>{title}</title>
    <path d={dPath} fill={fill} />
  </svg>
);

const IconWithDefaults = ({
  width = 24, height = 24, dPath, title, fill, ...rest
}: IconProperties) => (
  <Icon
    dPath={dPath}
    title={title}
    width={width}
    height={height}
    fill={fill}
    focusable={false}
    aria-hidden="true"
    tabIndex={-1}
    {...rest}
  />
);

Icon.propTypes = iconPropertyTypes;
IconWithDefaults.propTypes = iconPropertyTypes;

export {
  Icon as default,
  IconWithDefaults,
};
