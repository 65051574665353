import React from 'react';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import colors from '../Theme/colors';

const Search = (properties) => (
  <SearchOutlined
    titleAccess="Search"
    htmlColor={colors.greyDark}
    {...properties}
  />
);

export default Search;
