import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import TextField, { InputAdornment, IconButton, Variant } from './TextField';
import ClearIcon from './Icons/Clear';
import SearchIcon from './Icons/Search';
import breakpoints from './Theme/breakpoints';
import coalesceWithEmptyString from './lib/coalesceWithEmptyString';

export const SearchBoxContainer = styled.div`
  max-width: 840px;
  margin: auto;
  padding: 16px;

  @media only screen and (min-width: ${breakpoints.md}px) {
    padding: 0;
  }

  .MuiTextField-root {
    width: 100%;
  }

  i {
    height: 24px;
    width: 24px;
  }
`;

const searchFieldPropertyTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined'] as Variant[]),
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

type SearchFieldProperties = InferProps<
  typeof searchFieldPropertyTypes
>;

const SearchField = ({
  name,
  id,
  label,
  value,
  onChange,
  onClear,
  variant,
  ...rest
}: SearchFieldProperties) => (
  <TextField
    name={name ?? id}
    id={id}
    label={label}
    variant={variant ?? 'outlined'}
    value={coalesceWithEmptyString(value)}
    onChange={onChange}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {value
            ? (
              <IconButton onClick={onClear} size="large">
                <ClearIcon
                  data-testid="clearIcon"
                  fill="var(--grey-dark)"
                />
              </IconButton>
            )
            : (
              <IconButton size="large">
                <SearchIcon data-testid="searchIcon" />
              </IconButton>
            )}
        </InputAdornment>
      ),
    }}
    {...rest}
  />
);

SearchField.propTypes = searchFieldPropertyTypes;

export default SearchField;
