import React, { forwardRef } from 'react';
import MUIChip, { ChipProps } from '@mui/material/Chip';
import styled from '@emotion/styled';

const Chip = forwardRef<HTMLDivElement, ChipProps>((properties, reference) => (
  <MUIChip ref={reference} {...properties} />
));

export const StyledChip = styled(Chip)`
  font-weight: 500;
  font-size: .75rem;
`;

export default Chip;
