import React, {
  useCallback,
  Ref,
  ChangeEvent,
  ComponentProps,
} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUICheckbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import PropTypes, { InferProps } from 'prop-types';

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0 0 0 5px;
    display: inline;
  }

  .MuiFormControlLabel-label {
    color: var(--black-medium);
    font-family: var(--font-primary);
    transform: none;
    letter-spacing: unset;
    margin: 2px 0 0px;
  }

  .MuiButtonBase-root {
    padding: 3px;
    display: inline;
  }

  .MuiTypography-root {
    font-family: var(--font-secondary);
    display: inline;
  }

  input {
    height: 150%;
    margin-top: -16px;
  }
`;

const checkboxPropertyTypes = {
  checked: PropTypes.bool,
  ControlLabel: PropTypes.elementType,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
};

type CheckboxProperties = Omit<InferProps<
typeof checkboxPropertyTypes>, 'ControlLabel'> & {
  checked?: boolean;
  // eslint-disable-next-line max-len
  color?: 'default' | 'error' | 'info' | 'primary' | 'secondary' | 'success' | 'warning';
  ControlLabel?: React.ElementType | string;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
};

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProperties>(({
  ControlLabel = StyledFormControlLabel,
  checked = false,
  onChange,
  label,
  indeterminate = false,
  disabled = false,
  color = 'primary',
  id,
}: CheckboxProperties, reference) => (
  <ControlLabel
    control={(
      <MUICheckbox
        ref={reference}
        name={label}
        checked={checked}
        onChange={onChange}
        indeterminate={indeterminate}
        disabled={disabled}
        color={color}
        id={id}
      />
      )}
    label={label}
  />
));

Checkbox.propTypes = checkboxPropertyTypes;

const checkboxWithControllerPropertyTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  rules: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

type CheckboxWithControllerProperties = InferProps<
  typeof checkboxWithControllerPropertyTypes
> & {
  control: ComponentProps<typeof Controller>['control'];
  ControlLabel?: React.ElementType | string;
};

export const CheckboxWithController = ({
  name,
  control,
  rules,
  label,
  id,
  ControlLabel,
}: CheckboxWithControllerProperties) => {
  const render = useCallback(
    ({
      onChange, value, ref,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      onChange: (checked: boolean) => void;
      // eslint-disable-next-line react/no-unused-prop-types
      value: boolean;
      // eslint-disable-next-line react/no-unused-prop-types
      ref: Ref<HTMLButtonElement> | undefined;
    }) => (
      <Checkbox
        onChange={
          (
            { target: { checked } }: ChangeEvent<HTMLInputElement>,
          ) => onChange(checked)
        }
        checked={value}
        ref={ref}
        label={label}
        id={id}
        ControlLabel={ControlLabel}
      />
    ),
    [label, id, ControlLabel],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={render}
    />
  );
};

CheckboxWithController.propTypes = checkboxWithControllerPropertyTypes;

export default Checkbox;
