import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const clearPropertyTypes = {
  fill: PropTypes.string,
};

type ClearProperties = PropTypes.InferProps<typeof clearPropertyTypes> & {
  fill?: string;
};

const Clear = ({
  fill = 'var(--grey-dark)',
  ...properties
}: ClearProperties) => (
  <Icon
    // eslint-disable-next-line max-len
    dPath="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
    title="Clear"
    fill={fill}
    {...properties}
  />
);

Clear.propTypes = clearPropertyTypes;

export default Clear;
